import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AvatarDialogComponent } from "../avatar-dialog/avatar-dialog.component";
import { Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
@Component({
  selector: 'app-new-guide',
  templateUrl: './new-guide.component.html',
  styleUrls: ['./new-guide.component.scss']
})
export class NewGuideComponent implements OnInit {

  exampleForm: FormGroup;
  avatarLink: string = "https://proway.com.au/wp-content/plugins/elementor/assets/images/placeholder.png";

  validation_messages = {
   'name': [
     { type: 'required', message: 'Name is required.' }
   ],
   'category': [
     { type: 'required', message: 'Surname is required.' }
   ],
   'notes': [
     { type: 'required', message: 'Age is required.' },
   ]
 };
  // route: any;
  paramsObject: any;
  idParam : String
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private _location: Location
  ) { }

  ngOnInit() {
    this.createForm();
    // const idParam: string = this.route.snapshot.queryParamMap.get('id');
  }

  createForm() {
    this.exampleForm = this.fb.group({
      name: ['', Validators.required ],
      category: ['', Validators.required ],
      notes: ['', Validators.required ]
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarDialogComponent, {
      height: '400px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.avatarLink = result.link;
      }
    });
  }

  resetFields(){
    this.avatarLink = "https://proway.com.au/wp-content/plugins/elementor/assets/images/placeholder.png";
    this.exampleForm = this.fb.group({
      name: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
      notes: new FormControl('', Validators.required),
    });
  }

  onSubmit(value: any){
  //   this.route.queryParamMap
  //   .subscribe((params) => {
  //     this.paramsObject = { ...params.keys, ...params };
  //     console.log(this.paramsObject);
  //   }
  // );
  let idParam = this.route.snapshot.queryParamMap.get('id');
    this.firebaseService.createGuide(value, this.avatarLink,idParam)
    .then(
      res => {
        this.resetFields();
        this.router.navigate([this._location.back()]);
        // this.router.navigate(['/home/details/'+idParam]);
      }
    )
  }

}
