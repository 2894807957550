import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NewUserComponent } from './new-user/new-user.component';
import { NewGuideComponent } from './new-guide/new-guide.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditGuideComponent } from './edit-guide/edit-guide.component';
import { EditUserResolver } from './edit-user/edit-user.resolver';
import { EditGuideResolver } from './edit-guide/edit-guide.resolver';

export const rootRouterConfig: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'new-user', component: NewUserComponent },
  { path: 'new-guide', component: NewGuideComponent },
  { path: 'details/:id', component: EditUserComponent, resolve:{data : EditUserResolver} },
  { path: 'guidedetails/:id', component: EditGuideComponent, resolve:{data : EditGuideResolver} }
];
