// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyBMOaRr0NirNOX-eh2ITJBHUpL3ow8Hr4w",
    // authDomain: "splendid-ground-95214.firebaseapp.com",
    // databaseURL: "https://splendid-ground-95214.firebaseio.com",
    // projectId: "splendid-ground-95214",
    // storageBucket: "",
    // messagingSenderId: "429226112331"
    apiKey: "AIzaSyDfpJ0aTkVhIi3J3TgkvFcGH5nEF6xQGTE",
    authDomain: "home-guide-53c2e.firebaseapp.com",
    projectId: "home-guide-53c2e",
    storageBucket: "home-guide-53c2e.appspot.com",
    messagingSenderId: "211710023398",
    appId: "1:211710023398:web:4980ec3718721cdb8d4d27",
    measurementId: "G-2ZBNJVTML1"
  },
  
};

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDfpJ0aTkVhIi3J3TgkvFcGH5nEF6xQGTE",
//   authDomain: "home-guide-53c2e.firebaseapp.com",
//   projectId: "home-guide-53c2e",
//   storageBucket: "home-guide-53c2e.appspot.com",
//   messagingSenderId: "211710023398",
//   appId: "1:211710023398:web:4980ec3718721cdb8d4d27",
//   measurementId: "G-2ZBNJVTML1"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
