import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { MatDialogRef } from '@angular/material';
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { map, finalize, last, switchMap } from "rxjs/operators";
@Component({
  selector: 'app-avatar-dialog',
  templateUrl: './avatar-dialog.component.html',
  styleUrls: ['./avatar-dialog.component.scss']
})
export class AvatarDialogComponent implements OnInit {

  avatars: Array<any> = new Array<any>();
  selectedFile: File = null;
  downloadURL: Observable<string>;
  fb;
  constructor(
    public dialogRef: MatDialogRef<AvatarDialogComponent>,
    public firebaseService: FirebaseService,
    public storage: AngularFireStorage
  ) { }

  ngOnInit() {
    this.getData();
  }
  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `Images/${n}`;
    const fileRef = this.storage.ref(filePath);
    // var storageRef = this.storage
    // var storage = firebase.storage();
    // var uploadTask = storageRef.child('images/mountains.jpg').put(file);
    console.log("1");
    const task = this.storage.upload(`Images/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(downloadURL => {
            console.log(downloadURL);
            this.firebaseService.createImage(downloadURL)
            // fileUpload.url = downloadURL;
            // fileUpload.name = fileUpload.file.name;
            // this.saveFileData(fileUpload);
          });
        })
      ).subscribe();
        // finalize(() => {
          // console.log("2");
          // let downloadURL = fileRef.getDownloadURL();
          // this.firebaseService.createImage(fileRef.getDownloadURL())
          // fileRef.getDownloadURL().subscribe(url => {
            // console.log(url); // <-- do what ever you want with the url..
          // });
          // this.firebaseService.createImage(this.downloadURL)
          // this.downloadURL.subscribe(url => {
          //   if (url) {
          //     this.fb = url;
          //     this.firebaseService.createImage(url)
          //   }
          //   console.log("3");
          //   console.log(this.fb);
            
          // });
        // })
        // last(),  // emit the last element after task.snapshotChanges() completed
        // switchMap(() => fileRef.getDownloadURL())
      // )
      // .subscribe(url => {
        // if (url) {
          // console.log("3.5");
          // console.log(url);
          // here?
          // this.firebaseService.createImage(url)
        // }
        // this.firebaseService.createImage(url)
      // });
      // console.log("4");
  }
  getData(){
    this.firebaseService.getAvatars()
    .subscribe(data => this.avatars = data);
  }

  close(avatar){
    this.dialogRef.close(avatar);
  }

}
