import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AvatarDialogComponent } from "../avatar-dialog/avatar-dialog.component";
import { FirebaseService } from '../services/firebase.service';
import { Router, Params } from '@angular/router';
import {Location} from '@angular/common';
// import { NavigationService } from '@angular/navigation.service'
@Component({
  selector: 'app-edit-guide',
  templateUrl: './edit-guide.component.html',
  styleUrls: ['./edit-guide.component.scss']
})
export class EditGuideComponent implements OnInit {
  items: Array<any>;
  exampleForm: FormGroup;
  item: any;

  validation_messages = {
   'name': [
     { type: 'required', message: 'Name is required.' }
   ],
   'category': [
     { type: 'required', message: 'Surname is required.' }
   ],
   'notes': [
     { type: 'required', message: 'Age is required.' },
   ]
 };

  constructor(
    public firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _location: Location
  ) { }

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
        this.createForm();
        // this.getData(this.item.id);
      }
    });
    
  }

  // ngOnInit() {
  //   this.getData();
  // }

  // getData(id){
    // this.firebaseService.getUsers()
    // let id = 'qHxl3QYlcSO3GqqSTZ52'
    // this.firebaseService.getGuides(id)
    // .subscribe(result => {
    //   this.items = result;
    //   console.log(this.items)
      // this.age_filtered_items = result;
      // this.name_filtered_items = result;
  //   })
  // }

  createForm() {
    this.exampleForm = this.fb.group({
      name: [this.item.name, Validators.required],
      category: [this.item.category, Validators.required],
      notes: [this.item.notes, Validators.required]
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarDialogComponent, {
      height: '400px',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.item.avatar = result.link;
      }
    });
  }

  onSubmit(value){
    value.avatar = this.item.avatar;
    // value.age = Number(value.age);
    this.firebaseService.updateGuide(this.item.id, value)
    .then(
      res => {
        this.router.navigate([this._location.back()]);
      }
    )
  }

  delete(){
    this.firebaseService.deleteGuide(this.item.id)
    .then(
      res => {
        this.router.navigate([this._location.back()]);
      },
      err => {
        console.log(err);
      }
    )
  }

  cancel(){
    this.router.navigate([this._location.back()]);
  }

}
