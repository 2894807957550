import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(public db: AngularFirestore) {}

  getAvatars(){
      return this.db.collection('/images').valueChanges()
  }

  getUser(userKey){
    return this.db.collection('users').doc(userKey).snapshotChanges();
  } 
  getGuide(guideKey){
    return this.db.collection('guides').doc(guideKey).snapshotChanges();
  }

  updateUser(userKey, value){
    value.nameToSearch = value.name.toLowerCase();
    return this.db.collection('users').doc(userKey).set(value);
  }
  updateGuide(guideKey, value){
    return this.db.collection('guides').doc(guideKey).set(value, {merge: true});
  }

  deleteUser(userKey){
    return this.db.collection('users').doc(userKey).delete();
  } 
  deleteGuide(guideKey){
    return this.db.collection('guides').doc(guideKey).delete();
  }

  getUsers(){
    return this.db.collection('users').snapshotChanges();
  }

  getGuides(homes){
    // return this.db.collection('guides')
    // let guidesRef = this.db.collection('guides')
    // return guidesRef.where('home', '==', homes)
    // return this.db.collection('guides').where('home', '==', homes)
    return this.db.collection('guides',ref => ref.where('home', '==', homes))
    .snapshotChanges()
  }

  searchUsers(searchValue){
    return this.db.collection('users',ref => ref.where('nameToSearch', '>=', searchValue)
      .where('nameToSearch', '<=', searchValue + '\uf8ff'))
      .snapshotChanges()
  }

  searchUsersByAge(value){
    return this.db.collection('users',ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  }


  createUser(value, avatar){
    return this.db.collection('users').add({
      name: value.name,
      nameToSearch: value.name.toLowerCase(),
      surname: value.surname,
      age: parseInt(value.age),
      avatar: avatar
    });
  }
  createGuide(value, avatar, home){
    return this.db.collection('guides').add({
      name: value.name,
      // nameToSearch: value.name.toLowerCase(),
      category: value.category,
      notes: value.category,
      // notes: parseInt(value.age),
      avatar: avatar,
      home: home
    });
  }
  createImage(link){
    return this.db.collection('images').add({
      // name: value.name,
      // nameToSearch: value.name.toLowerCase(),
      // category: value.category,
      // notes: value.category,
      // notes: parseInt(value.age),
      link: link
      // home: home
    });
  }
}
